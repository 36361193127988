import axios from 'axios';
export const userToken = localStorage.getItem('EmployeeToken');
export const verifiactionDocumentSelectOptions = [
    'Police Verification',
    'Birth Certificate',
    'NID',
    'Driving Licence',
    'Passport',
    'Bike Papers',
  ];

export const religionSelectOptionValues = [
    'Islam',
    'Hindu',
    'Christian',
    'Buddhist',
    'Other',
];

export const howToFindUsSelectOptionValues = [
    'SH Member',
    'SH Share Holder',
    'News Paper',
    'Google',
    'Facebook',
    'SMS',
    'Youtube',
    'Parents',
    'TVC',
    "Friends",
    'Colleague',
    "I don't Know",
];

export const occupationSelectOptionValues = [
    'Student',
    'Job Holder',
    'Business Man',
    'Teacher',
    'Doctor',
    'Driver',
    'Housewife',
];

export const bloodGroupSelectOptionValues = [
    'A+',
    'A-',
    'B+',
    'B-',
    'O+',
    'O-',
    'AB+',
    'AB-',
    'Unknown',
];

export const bikeParkingSelectOptionValues = [
    'NO',
    'YES',
]

export const intialValues = { 
    phoneNumber: "", 
    email: "", 
    NidNumber: "",
    fullName: "",
    religion: "",     
    howToFIndUs: "",      
    referenceId: "",      
    image: "",      
    fathersName: "",      
    emergencyContactName: "",      
    emergencyNumber: "",      
    occupation: "",      
    bloodGroup: "",      
    memberType: "NEW", 
    address: "", 
    remarks: "",
    BookingDocumentMultipleSelect:"",
    policeVerification: '',
    birthCertificate: '',
    nid: '',
    drivingLicence: '',
    passport: '',
    bikePapers: '',
    branchName: "", 
    packageCategory: "",          
    checkInDate: "",          
    membershipType: "",          
    bedType: "",          
    selectedBed: "",          
    motobikeParking: "",          
    locker: "",          
    selectedLockers: "",          
    checkOutDate: "",          
    checkOutInfo: "",          
    secutityDeposite: "",          
};

export const apiClient = axios.create(
    {
    baseURL: 'http://erp.superhomebd.com/super_home_laravel/',
    withCredentials: true,
    },
    {
        headers: 
            {
                'Accept':'application/json',
                'Access-Control-Allow-Origin': '*',
            }
    }
);